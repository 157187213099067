import cls from 'classnames';
import {ElementType, forwardRef, ReactElement} from 'react';
import {PolymorphicComponentPropWithRef, PolymorphicRef} from 'Shared/@types/react';
import {BaseButton} from './BaseButton';

type SecondaryButtonProps<C extends ElementType> = PolymorphicComponentPropWithRef<C>;

type SecondaryButtonComponent = <C extends ElementType = 'button'>(
  props: SecondaryButtonProps<C>,
) => ReactElement | null;

export const SecondaryButton: SecondaryButtonComponent = forwardRef(<C extends ElementType = 'button'>(
    {className, ...props}: SecondaryButtonProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const baseClasses = `
      h-11.5 px-6 flex gap-x-3 items-center shadow rounded border border-white
      text-base text-gray-850 bg-white transition ease-in-out duration-150
      hover:text-cyan focus:outline-none focus-ring
      active:shadow-big-circle shadow-none active:shadow-gray-700 duration-500
      disabled:cursor-default disabled:bg-white/50
    `;

    return <BaseButton {...props} className={cls(baseClasses, className)} ref={ref}/>;
  },
);
