import {ExclamationCircleIcon} from '@heroicons/react/24/outline';
import {createInertiaApp, Link, router} from '@inertiajs/react';
import {JSXElementConstructor, StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {PrimaryButton} from 'Shared/Components/Buttons';
import {FormErrorContextProvider, InitialApplicationContext} from 'Shared/Contexts';
import 'Shared/Support/yupExtensions';
import {ErrorBoundary} from 'react-error-boundary';
import route from 'ziggy-js';

type CreateInertiaAppProps = {
  analytics?: boolean;
  errorTracking?: boolean;
  progress?:
    | undefined
    | {
    delay?: number;
    color?: string;
    includeCSS?: boolean;
    showSpinner?: boolean;
  }
    | false;
};

type InitInertiaAppProps = {
  resolve: (name: string) => Promise<JSXElementConstructor<any>>;
  layout: JSXElementConstructor<any>;
} & CreateInertiaAppProps;


function ErrorFallback() {
  return (
    <div className="w-full text-gray-800 -mt-20 h-full max-w-screen-3xl flex flex-col justify-center items-center">
      <div>
        <ExclamationCircleIcon className="h-20 w-20 text-orange" />
      </div>
      <div className="text-2xl font-medium mt-3 sm:mt-5">Whoops! Something went wrong.</div>
      <div className="mt-2">Funeral Stream has been notified.</div>
      <div className="flex gap-4 mt-5 sm:mt-6">
        <PrimaryButton onClick={() => window.location.reload()}>Reload</PrimaryButton>
        <Link href={route('logout')}>
          <PrimaryButton>
            Logout
          </PrimaryButton>
        </Link>
      </div>
    </div>
  );
}

export const initGoogleAnalytics = () => {
  if (typeof window.gtag === 'function') {
    let initialPageView = true;
    router.on('navigate', (event) => {
      if (initialPageView) {
        initialPageView = false;
      } else {
        window.gtag('event', 'page_view', {
          page_location: event.detail.page.url,
        });
      }
    });
  }
};

export const initErrorTracking = async () => {
  if (window.sentryDsn) {
    const Sentry = await import('@sentry/react');
    const {BrowserTracing} = await import('@sentry/tracing');

    Sentry.init({
      dsn: window.sentryDsn,
      integrations: [new BrowserTracing()],
      environment: window.sentryEnvironment,
      tracesSampleRate: window.sentryTracesSampleRate,
      ignoreErrors: [
        'ChunkLoadError',
        'CanceledError',
        'Cannot read properties of undefined (reading \'default\')'
      ],
    });
  }
};

export const initInertiaApp = async ({
  resolve: resolveComponent,
  layout: Layout,
  progress,
  errorTracking = true,
  analytics = true,
}: InitInertiaAppProps) => {
  if (errorTracking) {
    await initErrorTracking();
  }

  if (analytics) {
    initGoogleAnalytics();
  }

  return createInertiaApp<Window['inertiaInitialPageData']['props']>({
    progress: progress === undefined ? {showSpinner: true} : progress,
    page: window.inertiaInitialPageData,
    resolve(name) {
      return resolveComponent(name).catch((e) => {
        // Probably a stale cache after a deployment, reload the page
        if (e.message.includes('Failed to fetch dynamically imported module')) {
          window.location.reload();
        } else {
          throw new Error(`Inertia failed to load the page component for "${name}".`, {
            cause: e,
          });
        }
      });
    },
    setup({el, props, App}) {
      const initialApplication = {application: props.initialPage.props.application};

      createRoot(el).render(
        <StrictMode>
          <InitialApplicationContext.Provider value={initialApplication}>
            <App {...props}>
              {({key, props, Component}) => (
                <FormErrorContextProvider>

                  <Layout>
                    <ErrorBoundary
                      FallbackComponent={() => (
                        <ErrorFallback/>
                      )}>
                      <Component key={key} {...props} />
                    </ErrorBoundary>
                  </Layout>
                </FormErrorContextProvider>
              )}
            </App>
          </InitialApplicationContext.Provider>
        </StrictMode>,
      );
    },
  });
};
