import cls from 'classnames';
import * as React from 'react';
import {ComponentType, ElementType, forwardRef, ReactElement} from 'react';
import {PolymorphicRef} from 'Shared/@types/react';
import {Tooltip} from 'Shared/Components';
import {BaseButton, BaseButtonProps} from './BaseButton';

type PageHeaderButtonProps<C extends React.ElementType> = BaseButtonProps<C, {
  iconClasses?: string;
  icon: ComponentType<any>;
}>;

export type PageHeaderButtonComponent = <C extends ElementType = 'button'>(
  props: PageHeaderButtonProps<C>,
) => ReactElement | null;

export const PageHeaderButton: PageHeaderButtonComponent = forwardRef(<C extends ElementType = 'button'>(
    {
      className = '',
      icon: Icon,
      iconClasses = 'h-6 w-6 flex-shrink-0',
      title,
      ...props
    }: PageHeaderButtonProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const baseClasses = `focus:outline-none focus:text-pink hover:text-pink`;

    return (
      <Tooltip content={title}>
        <BaseButton className={cls(baseClasses, className)} {...props} ref={ref}>
          <Icon className={iconClasses}/>
        </BaseButton>
      </Tooltip>
    );
  },
);