import cls from 'classnames';
import { format as formatDate, startOfDay } from 'date-fns';
import { InputHTMLAttributes, forwardRef } from 'react';

export const TextField = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & { prefixInline?: boolean }>(
  ({ type = 'text', className = '', prefix, prefixInline = false, ...props }, ref) => {
    let format = '';
    let formatDates = false;
    if (type === 'date') {
      format = 'yyyy-MM-dd';
      formatDates = true;
    } else if (type === 'datetime') {
      type = 'datetime-local';
      format = "yyyy-MM-dd'T'HH:mm";
      formatDates = true;
    }

    if (formatDates && props.max === 'today') {
      props.max = formatDate(startOfDay(new Date()), format);
    }

    if (formatDates && props.min === 'today') {
      props.min = formatDate(startOfDay(new Date()), format);
    }

    if (formatDates && props.max === 'now') {
      props.max = formatDate(new Date(), format);
    }

    if (formatDates && props.min === 'now') {
      props.min = formatDate(new Date(), format);
    }

    if (formatDates && !props.max) {
      props.max = '9999-12-31';
    }

    if (prefixInline && prefix !== undefined) {
      return (
        <div className="focus-within:focus-ring flex h-10.5 items-center rounded border border-gray-600 bg-gray-100 text-sm shadow-input focus-within:bg-white">
          <div className="z-10 flex h-full max-w-xs cursor-text items-center rounded-l pl-2 text-gray-1000">
            <span className="select-none truncate">{prefix}</span>
          </div>
          <input
            type={type}
            ref={ref}
            maxLength={props?.maxLength ? props.maxLength : 255}
            className="h-full flex-grow rounded-r border-0 bg-transparent pl-0 text-sm placeholder-gray-800 focus:ring-0"
            {...props}
          />
        </div>
      );
    }

    if (prefix !== undefined) {
      return (
        <div className="focus-within:focus-ring flex h-10.5 items-center rounded border border-gray-600 bg-gray-100 text-sm">
          <div className="flex h-full max-w-xs cursor-text items-center rounded-l border-r border-gray-600 bg-gray-400 px-2 text-gray-1000 shadow-input">
            <span className="select-none truncate">{prefix}</span>
          </div>
          <input
            type={type}
            ref={ref}
            maxLength={props?.maxLength ? props.maxLength : 255}
            className="h-full flex-grow rounded-r border-0 bg-transparent text-sm placeholder-gray-800 shadow-input focus:bg-white focus:ring-0"
            {...props}
          />
        </div>
      );
    }

    return (
      <input
        type={type}
        ref={ref}
        maxLength={props?.maxLength ? props.maxLength : 255}
        className={cls(
          `border-1 focus-ring focus:shadow:none h-10.5 w-full rounded border-gray-600 bg-gray-100 pl-4 text-sm text-blackish placeholder-gray-800 shadow-input focus:bg-white`,
          className
        )}
        {...props}
      />
    );
  }
);
