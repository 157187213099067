import { ReactElement, RefAttributes, useMemo } from 'react';
import Select, { components, GroupBase, OptionProps, Props, SelectInstance, StylesConfig } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import theme from 'Shared/Support/tailwind-theme';

const {
  spacing: twSpacing,
  backgroundColor: twBackgroundColor,
  borderColor: twBorderColor,
  borderRadius: twBorderRadius,
  boxShadow: twBoxShadow,
  fontSize: twFontSize,
  ringOpacity: twRingOpacity,
  textColor: twTextColor,
} = theme;

export interface CustomOptionProps extends Record<string | number, any> {
  description?: string;
  isDisabled?: boolean;
  fixed?: boolean;
}

export const getStyles = <
  Option extends CustomOptionProps,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(): StylesConfig<Option, IsMulti, Group> => ({
  container: () => ({
    position: 'relative',
    width: '100%',
  }),
  control: (provided, { isFocused }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: twSpacing[10.5],
    position: 'relative',
    cursor: 'text',
    fontSize: twFontSize.sm[0] as string,
    color: twTextColor.blackish,
    background: isFocused ? twBackgroundColor['white'] : twBackgroundColor.gray[100],
    borderRadius: twBorderRadius.DEFAULT,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isFocused ? twBorderColor.cyan.DEFAULT : twBorderColor.gray[600],
    '--tw-shadow': twBoxShadow.input,

    // Start .focus-ring
    '--tw-ring-opacity': twRingOpacity[20],
    '--tw-ring-color': 'rgba(38, 198, 218, var(--tw-ring-opacity))', // ring-cyan
    '--tw-ring-offset-width': '0px',
    '--tw-ring-offset-shadow': 'var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)', //ring-4
    '--tw-ring-shadow': 'var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
    boxShadow: isFocused ? 'var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)' : twBoxShadow.input,
    // End .focus-ring
  }),
  input: (provided) => ({
    ...provided,
    'input:focus': {
      fontSize: 'inherit',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: (provided, { selectProps: { isClearable, value } }) => {
    return isClearable && value ? provided : { display: 'none' };
  },
  clearIndicator: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided, { isDisabled }) => ({
    ...provided,
    cursor: 'pointer',
    color: twTextColor.gray[800],
    ...(isDisabled ? { display: 'none' } : {}),
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '1rem',
    paddingRight: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'inherit',
  }),
  multiValue: (provided, state) => {
    return state.data.fixed
      ? {
          ...provided,
          paddingRight: '6px',
          outline: '1px solid rgba(0, 0, 0, 0.2)',
          opacity: '0.75',
        }
      : provided;
  },
  multiValueRemove: (provided, state) => {
    return state.data.fixed ? { ...provided, display: 'none' } : provided;
  },
  menuList: (provided) => ({
    ...provided,
    fontSize: twFontSize.sm[0] as string,
  }),
  option: (provided, { isDisabled }) => ({
    ...provided,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (base) => ({ ...base, zIndex: 10 }),
});

const OptionWithDescription = <
  Option extends CustomOptionProps,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  ...props
}: OptionProps<Option, IsMulti, Group>) => {
  const DefaultOption = components.Option;

  if (!('description' in props.data)) return <DefaultOption {...props}>{children}</DefaultOption>;
  return (
    <DefaultOption {...props}>
      <div>{children}</div>
      <div className="text-sm text-gray-750">{props.data.description}</div>
    </DefaultOption>
  );
};

const useSelectOptions = <Option extends CustomOptionProps, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: CustomSelectProps<Option, IsMulti, Group> | AsyncSelectProps<Option, IsMulti, Group>
): Props<Option, IsMulti, Group> | AsyncProps<Option, IsMulti, Group> => {
  const defaultStyles = useMemo(() => props.styles ?? getStyles<Option, IsMulti, Group>(), [props.styles]);

  return {
    ...props,
    menuPlacement: props.menuPlacement ?? 'auto',
    styles: defaultStyles,
    onChange(option, meta) {
      // block removal of options if they are fixed
      if (meta.action === 'remove-value' || meta.action === 'pop-value') {
        if (typeof meta.removedValue === 'object' && meta.removedValue?.fixed) {
          return;
        }
      }

      props.onChange?.(option, meta);
    },
    components: {
      Option: OptionWithDescription,
      ...props.components,
    },
  };
};

export type CustomSelectProps<
  Option extends CustomOptionProps,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & RefAttributes<SelectInstance<Option, IsMulti, Group>> & { labelKey?: string; valueKey?: string };

export const SelectField = <Option extends CustomOptionProps, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: CustomSelectProps<Option, IsMulti, Group>
): ReactElement<Select> => {
  const modifiedProps = useSelectOptions<Option, IsMulti, Group>(props);
  return <Select<Option, IsMulti, Group> {...modifiedProps} />;
};

export type AsyncSelectProps<
  Option extends CustomOptionProps,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = AsyncProps<Option, IsMulti, Group> & RefAttributes<SelectInstance<Option, IsMulti, Group>> & { labelKey?: string; valueKey?: string };

export const AsyncSelectField = <
  Option extends CustomOptionProps,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: AsyncSelectProps<Option, IsMulti, Group>
): ReactElement<AsyncSelect> => {
  const modifiedProps = useSelectOptions<Option, IsMulti, Group>(props);
  return <AsyncSelect<Option, IsMulti, Group> {...modifiedProps} />;
};
