import { forwardRef, useEffect, useMemo, useState } from 'react';
import { ActionMeta, GroupBase, OnChangeValue, SelectInstance } from 'react-select';
import Select, { CreatableProps } from 'react-select/creatable';
import { isEmptyValue } from 'Shared/Support/valueHelpers';
import usePlacesAutocomplete, { getGeocode, getLatLng, RequestOptions } from 'use-places-autocomplete';
import { getStyles } from './SelectField';

export type OptionProps = {
  label: string | undefined;
  value: string | undefined;
};

export type AddressFieldProps = Omit<CreatableProps<OptionProps, false, GroupBase<OptionProps>>, 'value' | 'onChange'> & {
  country?: string | string[];
  value?: string;
  onChange?: (value: string | undefined, meta: ActionMeta<OptionProps>) => void;
  onGeocodeStart?: () => void;
  onGeocode?: (lat: number, lng: number) => void;
  onGeocodeError?: (error: Error) => void;
};

export const AddressField = forwardRef<SelectInstance<OptionProps>, AddressFieldProps>(
  ({ country = ['NZ', 'AU'], value, onGeocodeStart, onGeocode, onGeocodeError, ...props }, ref) => {
    const [locationBounds, setLocationBounds] = useState<google.maps.LatLng | undefined>();
    const [currentInputValue, setCurrentInputValue] = useState(value);

    const [sessionToken] = useState(() => new google.maps.places.AutocompleteSessionToken());
    const [loading, setLoading] = useState(false);

    const requestOptions = useMemo(() => {
      const options: RequestOptions = {
        sessionToken,
        componentRestrictions: {
          country: Array.isArray(country) ? country : [country],
        },
      };

      if (locationBounds) {
        options.location = locationBounds;
        options.radius = 10000;
      }

      return options;
    }, [country, locationBounds, sessionToken]);

    const places = usePlacesAutocomplete({
      // cache: false,
      debounce: 150,
      defaultValue: currentInputValue,
      requestOptions,
    });

    const {
      value: autocompleteValue,
      setValue: setAutocompleteValue,
      suggestions: { loading: apiLoading, status: apiStatus, data },
      clearSuggestions,
      clearCache,
    } = places;

    useEffect(() => {
      clearCache();
    }, [clearCache, locationBounds]);

    useEffect(() => {
      if (!apiLoading && loading) {
        setLoading(false);
      }
    }, [apiStatus]);

    const options = useMemo(
      () => data.map(({ description }) => ({ value: description, label: description })),
      [autocompleteValue, loading, data.length]
    );

    const onInputChange = (value, { action }) => {
      if (action !== 'input-change') return;
      setCurrentInputValue(value);

      clearSuggestions();

      if (value.trim().length > 0) {
        setLoading(true);
        setAutocompleteValue(value.trim());
      }
    };

    const onChange = (option: OnChangeValue<OptionProps, false>, meta: ActionMeta<OptionProps>) => {
      setCurrentInputValue(option?.label || 'empty');
      if (typeof onGeocode === 'function' && option?.label) {
        onGeocodeStart?.();
        getGeocode({ address: option.label })
          .then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            onGeocode(lat, lng);
          })
          .catch((e) => {
            onGeocodeError?.(e);
          });
      }
      props.onChange?.(option?.value, meta);
    };

    const onFocus = () => {
      navigator.geolocation.getCurrentPosition(function ({ coords: { latitude: lat, longitude: lng } }) {
        setLocationBounds(new google.maps.LatLng({ lat, lng }));
      });
    };

    const valueOption = useMemo(() => (value ? { label: value, value: value } : undefined), [value]);

    const modifiedProps: CreatableProps<OptionProps, false, GroupBase<OptionProps>> = {
      ...props,
      options,
      value: valueOption,
      isValidNewOption: (inputValue) => {
        if (isEmptyValue(inputValue)) return false;

        // Don't show the create option if an existing option is an exact match
        return !options.some((option) => option.value === inputValue);
      },
      onInputChange,
      onFocus,
      onChange,
      isLoading: loading,
      defaultInputValue: currentInputValue,
      allowCreateWhileLoading: false,
      formatCreateLabel: (value) => value,
      styles: getStyles(),
      components: {
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      },
      noOptionsMessage: () => null,
      loadingMessage: () => null,
      filterOption: () => true,
    };

    return <Select<OptionProps> ref={ref} {...modifiedProps} />;
  }
);
