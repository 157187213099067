import { createAxiosClient } from 'Shared/Support';
import { CancelTokenSource } from 'axios';
import prettyBytes from 'pretty-bytes';
import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import route from 'ziggy-js';
import { getAccept } from './useS3Uploader';

const axiosFS = createAxiosClient({ concurrency: 5, withCredentials: true });
const axiosS3 = createAxiosClient({ concurrency: 5, withCredentials: false });

type useS3UploadMultipart = {
  accept: string;
  onUploadStart?: () => void;
  onUploadComplete: (pendingImage: any, presignedUploadUrl: any) => void;
  setProgress: Dispatch<SetStateAction<number>>;
  setMeta: (message: string) => void;
  onThumbnailCreated?: (url: any) => void;
  onUploadCancelled?: (message: string) => void;
  onUploadFailed?: (message: string) => void;
  onFileSelected?: (pendingImage: any, presignedUploadUrl: any) => Promise<any>;
  onFileAccepted?: (pendingImage: any, presignedUploadUrl: any) => Promise<any>;
  chunkSize?: any;
};

export function useS3UploadMultipart({
  accept,
  onUploadStart,
  onUploadComplete,
  setProgress,
  setMeta,
  onThumbnailCreated,
  onUploadCancelled,
  onUploadFailed,
  onFileSelected,
  onFileAccepted,
  chunkSize,
}: useS3UploadMultipart) {
  const imageWidthLimit = 4000;
  const imageHeightLimit = 4000;

  const cancelToken = useRef<CancelTokenSource>(null!);

  const requestWrapper = useCallback(
    async function requestWrapper(request) {
      try {
        return (await request()).data;
      } catch (err) {
        if (axiosFS.isCancel(err)) {
          if (onUploadCancelled) {
            onUploadCancelled(err.message);
          }
        } else {
          console.error(err);
          if (onUploadFailed) {
            onUploadFailed(err.message);
          }
        }
        return false;
      }
    },
    [onUploadCancelled, onUploadFailed]
  );

  async function checkImageRestrictions(image, url) {
    return await new Promise((resolve) => {
      image.onload = () => {
        if (image.width > imageWidthLimit) {
          resolve(`Failed to upload Image. Please make sure it is not wider than ${imageWidthLimit}px`);
        }
        if (image.height > imageHeightLimit) {
          resolve(`Failed to upload Image. Please make sure it is not larger than ${imageHeightLimit}px`);
        }
        resolve('');
      };
      image.src = url;
    });
  }

  async function onDrop([pendingFile]) {
    setProgress(0);
    if (onUploadStart) {
      onUploadStart();
    }
    let url;

    if (pendingFile.path.includes('.gif')) {
      if (onUploadFailed) {
        onUploadFailed('GIFs are not supported');
      }
      return;
    }
    console.log(pendingFile.path);
    if (pendingFile.type.split('/')[0] === 'image') {
      url = URL.createObjectURL(pendingFile);
      const image = new Image();
      const errorMessage = (await checkImageRestrictions(image, url)) as string;
      if (errorMessage !== '') {
        URL.revokeObjectURL(url);
        if (onUploadFailed) {
          onUploadFailed(errorMessage);
        }
        return;
      }
      if (typeof onThumbnailCreated === 'function') {
        onThumbnailCreated(url);
      }
    }

    if (typeof onFileSelected === 'function') {
      const errorMessage = (await onFileSelected(pendingFile, url)) as string;
      if (errorMessage) {
        URL.revokeObjectURL(url);
        if (onUploadFailed) {
          onUploadFailed(errorMessage);
        }
        return;
      }
    }

    if (typeof onFileAccepted === 'function') {
      pendingFile = (await onFileAccepted(pendingFile, url)) as string;
    }

    cancelToken.current = axiosFS.CancelToken.source();
    if (typeof setMeta === 'function') {
      setMeta(`Preparing upload of ${prettyBytes(pendingFile.size)} - 0%`);
    }

    const totalParts = Math.ceil(pendingFile.size / chunkSize);

    // Initialize the S3 Upload
    const idRequest = await requestWrapper(() =>
      axiosFS.post(
        route('global.s3.create-multipart'),
        {
          type: pendingFile.type,
          filename: pendingFile.name,
        },
        { cancelToken: cancelToken.current.token }
      )
    );

    if (!idRequest) {
      return;
    }

    const uploadId = idRequest?.uploadId;

    // Get the S3 Upload URL for each part
    const partRequest = await requestWrapper(() =>
      axiosFS.request({
        url: route('global.s3.create-part-urls', { uploadId, totalParts }),
        method: 'post',
        cancelToken: cancelToken.current.token,
      })
    );

    if (!partRequest) {
      return;
    }
    const presignedUrls = partRequest?.presignedUrls;

    const partUploads: { partNumber: number; progress: number; size: number; uploaded: number; eTag: any; promise: () => Promise<void> }[] = [];
    let totalUploaded = 0;

    for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
      const partUploadsIndex = partUploads.length;
      partUploads.push({
        partNumber: partNumber,
        progress: 0,
        size: 0,
        uploaded: 0,
        eTag: null,
        promise: async function partUpload() {
          let remainingAttempts = 3;
          const fileChunk = pendingFile.slice(partUploadsIndex * chunkSize, Math.min((partUploadsIndex + 1) * chunkSize, pendingFile.size));

          do {
            try {
              remainingAttempts--;
              partUploads[partUploadsIndex].progress = 0;
              partUploads[partUploadsIndex].uploaded = 0;
              partUploads[partUploadsIndex].size = fileChunk.size;
              const response = await axiosS3.put(presignedUrls[partNumber], fileChunk, {
                cancelToken: cancelToken.current.token,
                onUploadProgress(progressEvent) {
                  const newBytes = progressEvent.loaded - partUploads[partUploadsIndex].uploaded;
                  partUploads[partUploadsIndex].uploaded += newBytes;
                  totalUploaded += newBytes;
                  const totalProgress = Math.round((totalUploaded * 100) / pendingFile.size);
                  partUploads[partUploadsIndex].progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  setProgress(Math.round((totalUploaded * 100) / pendingFile.size));
                  if (typeof setMeta === 'function') {
                    setMeta(`Uploaded ${prettyBytes(totalUploaded)} of ${prettyBytes(pendingFile.size)} - ${totalProgress}%`);
                  }
                },
              });

              partUploads[partUploadsIndex].eTag = response.headers.etag;
              console.log(`Part ${partUploads[partUploadsIndex].partNumber} Upload Finished`);

              remainingAttempts = 0;
            } catch (e) {
              totalUploaded -= partUploads[partUploadsIndex].uploaded;
              partUploads[partUploadsIndex].uploaded = 0;
              setProgress(Math.round((totalUploaded * 100) / pendingFile.size));

              if (axiosS3.isCancel(e)) {
                throw e;
              }

              if (remainingAttempts === 0) {
                console.log(`Part ${partUploads[partUploadsIndex].partNumber} Upload Failed: ${e.message}`);
                console.error(e);
                throw e;
              }
            }
          } while (remainingAttempts > 0);
        },
      });
    }

    try {
      await Promise.all(partUploads.map((partUpload) => partUpload.promise()));
      const multiPartComplete = await axiosFS.post(
        route('global.s3.complete-multipart', { uploadId }),
        {
          parts: partUploads.map((partUpload) => ({
            ETag: partUpload.eTag,
            PartNumber: partUpload.partNumber,
          })),
        },
        { cancelToken: cancelToken.current.token }
      );
      onUploadComplete(pendingFile, { key: multiPartComplete.data.key });
    } catch (err) {
      if (!axiosFS.isCancel(err)) {
        console.error(err);
      }
      cancelToken.current.cancel(err.message);
      await axiosFS.post(route('global.s3.abort-multipart', { uploadId }));
      if (axiosFS.isCancel(err)) {
        if (onUploadCancelled) {
          onUploadCancelled(err.message);
        }
      } else {
        console.error(err);
        if (onUploadFailed) {
          onUploadFailed(err.message);
        }
      }
    }
  }

  const dropZoneProps = useDropzone({
    accept: getAccept(accept),
    onDropAccepted: onDrop,
    useFsAccessApi: false,
  });

  return {
    ...dropZoneProps,
    cancel: (reason) => cancelToken.current?.cancel(reason),
  };
}
