import cls from 'classnames';
import { forwardRef, TextareaHTMLAttributes } from 'react';

export const TextAreaField = forwardRef<HTMLTextAreaElement, TextareaHTMLAttributes<HTMLTextAreaElement>>(({ className = '', ...props }, ref) => {
  return (
    <textarea
      ref={ref}
      className={cls(
        'border-1 min-h-24 w-full rounded border-gray-600 bg-gray-100 py-2.5 pl-4 text-sm text-blackish placeholder-gray-800 shadow-input',
        'focus-ring focus:shadow:none focus:bg-white',
        className
      )}
      {...props}
    />
  );
});
